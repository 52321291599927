<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/articles/manual_picture.jpg`)" height="200px"></v-img>

      <v-card-title>
        Denon PMA-560 Service Manual
      </v-card-title>

      <!-- <v-card-subtitle>
        some subtitle
           </v-card-subtitle> -->


      <v-card-text>
        <p>Having seen there is no good scan of an English PMA 560 service manual, I scanned mine and converted it to PDF <tt>:-)</tt>.</p>

        <p>Later I'll add a higher resolution download of the circuit diagram.</p>

      </v-card-text>

      <v-card-actions>
        <v-btn color="orange lighten-2" text @click="download_manual">
          Download
        </v-btn>

        <v-spacer></v-spacer>

      </v-card-actions>

    </v-card>
    <social />
  </v-container>
</template>

<script>
  export default {
    name: 'ServiceManual',

    components: {
      Social: () => import('@/components/home/Social')
    },

    methods: {
      download_manual() {
        //        console.log('download manual');

        window.open('pma560_service_manual.pdf', '_blank');

      }
    }
  }
</script>
