import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import ServiceManual from '../views/ServiceManual.vue'
import RelayRepair from '../views/RelayRepair.vue'
import Prices from '../views/Prices.vue'
import Brochure from '../views/Brochure.vue'
import Restore from '../views/Restore.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/service_manual',
      name: 'service_manual',
      component: ServiceManual
    },
    {
      path: '/relay_repair',
      name: 'relay_repair',
      component: RelayRepair
    },
    {
      path: '/prices',
      name: 'prices',
      component: Prices
    },
    {
      path: '/brochure',
      name: 'brochure',
      component: Brochure
    },
    {
      path: '/restore',
      name: 'restore',
      component: Restore
    }    
  ],
})
