<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/articles/refurb/soldering_irons_small.jpg`)" max-height="320"></v-img>

      <v-card-title>
        Denon PMA 560 Restoration / Refurb / Recap / Rejuvenation / Overhaul &nbsp;&nbsp;&nbsp;&nbsp; <tt>:-)</tt>
      </v-card-title>

      <!-- <v-card-subtitle>
        some subtitle
           </v-card-subtitle> -->


      <v-card-text class="text-body-1">
        <p>Fellow Denon PMA enthusiast

          <a target="_blank" href="https://www.diyaudio.com/community/members/karl-vd-berg.227731/">Karl vd Berg</a>
          from Amsterdam bought a PMA 560 off <em>marktplaats</em> for 105 euros (including shipping)
          and set about restoring and improving it.
        </p>

        <p>He did quite a comprehensive job, as you'll see below.</p>

        <p>
          <div class="text-h4">The Plan</div><br>

          "I will replace the relay for sure.
          I want to put 4x 8200uF caps in a CRC fashion to reduce greatly ripples and make the amp super quiet (from PSU upstream) and more dynamic."<br><br>

          "Then replace all the other caps with Nichicon KL or ES series caps and bypass each of them with a 0.1 film cap and improving capacitance where is necessary.
          Then replace the old 4558 opamp in the preamp with a Fet one (maybe an OPA2107) to provide less offset from the preamp.
          Finally, replace some resistors in the audio path (not many) with bulk foil types.
          This amp has enormous potential for improvement in performance."
        </p>

        <p>
          <div class="text-h4">The Steps</div><br>

          <ol>
            <li>disassemble and deep cleaning of all switches, pots, and mechanical parts (hell of a job, very time-consuming)
              <br clear="left">

              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/001.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/002.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/003.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/004.jpg`)"></zoom-on-hover>
              </div>

              <br clear="left">

            </li>
            <li>reinforcing some traces with heavier gauge wires
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/005.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>replaced all the Zener diodes and all generic capacitors with Nichicon UKL low leakage caps. Some were really low in capacitance, like C603 330uF 6.3V (was 270uF)</li>

            <li>removing old JCR4558 opamp
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/006.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>made a hole in front panel to have access to opamps
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/009.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>putting a sip-8 socket to try newer/other opamps, and Nichicon MUSE capacitors in the preamp section
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/007.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>installed FET opamp TL072 (BCP version), much better than stock JCR4558
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/008.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/010.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>installed four 8,200uF 63V Hi Sound Nichicon LKS audio power caps
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/011.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/016.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>replaced the bias pots with multi-turn ones, and drivers as well
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/017b.jpg`)"></zoom-on-hover>
              </div>
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/017d.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">

            </li>

            <li>set bias</li>
          </ol>


        </p>

        <p>
          <div class="text-h4">Parts Info</div><br>

          <ol>
            <li>the stock S4VB20 (200V 4A) smaller rectifier bridge was replaced with a stronger
              <a href="https://nl.mouser.com/ProductDetail/Vishay-Semiconductors/VS-KBPC808?qs=9h5moqLwYqRJomf2vzGq1Q%3D%3D">BR84 type</a> (400V 8A), because of the increased capacitance bank
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/014.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

            <li>almost all the electrolytic caps are
              <a href= "https://nl.mouser.com/Nichicon/Passive-Components/Capacitors/Aluminum-Electrolytic-Capacitors/Aluminum-Electrolytic-Capacitors-Radial-Leaded/UKL-Series/_/N-75hqwZ1yzvvqx?P=1yi2vyoZ1z0zlbm">
                Nichicon UKL</a> (low leakage, 10% tol.), except the main 4 big ones which are
              <a href="https://nl.mouser.com/Nichicon/Passive-Components/Capacitors/Aluminum-Electrolytic-Capacitors/Aluminum-Electrolytic-Capacitors-Snap-In/LKS-Series/_/N-75hr0Z1yzvvqx?P=1yzucycZ1z0zlbm">
                LKS type</a>.
              (only two 10uF in the preamp board are
              <a href="https://nl.mouser.com/Nichicon/Passive-Components/Capacitors/Aluminum-Electrolytic-Capacitors/Aluminum-Electrolytic-Capacitors-Radial-Leaded/Muse/_/N-75hqwZ1yzvvqx?P=1z0zlbmZ1yx76luZ1yztuat">
                Nichicon Muse</a> ones as seen in one of the pictures)</li>

            <li><a href="https://nl.mouser.com/Vishay/Semiconductors/Discrete-Semiconductors/Diodes-Rectifiers/Zener-Diodes/_/N-ax1mhZ1yzvvqx?P=1z0z63xZ1yw803nZ1z0w5gnZ1z0zls5">
              new Zener diodes</a>
              are both 18V and 36V from Vishay 2% tol. They are important because they regulate many important parts in the whole amp. Stock ones were worn out a bit
              <br clear="left">
              <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
                <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/017a.jpg`)"></zoom-on-hover>
              </div>
              <br clear="left">
            </li>

          </ol>
        </p>

        <p>
          <div class="text-h4">Final Steps</div><br>

          I set the starting bias at 3mV and left the optical 30mV for later when I find a proper signal generator.<br><br>

          Just to say that the PMA-560 is finished and sounding
          powerful, effortless, and crystal clear.

          <br clear="left">
          <div style="width:400px" class="float-left ma-2 mt-4 mb-4">
            <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/013.jpg`)"></zoom-on-hover>
          </div>
          <div style="width:600px" class="float-left ma-2 mt-4 mb-4">
            <zoom-on-hover :img-normal="require(`@/assets/articles/refurb/012.jpg`)"></zoom-on-hover>
          </div>
          <br clear="left">

          The Denon is playing better and better as new caps are
          burning in. I replaced the drivers with MJE172/182's
          (remember, it's a testbed) and the bass is HUGE and well
          defined, but probably because the new components are
          breaking in.

          Original drivers are 2SD2004, but its Ic is just 1.2A for
          such an amp. The MJE172/182 have their Ic for 3A, more than
          double. So, more capable of sudden high currents.  This amp
          simply rocks! Strange that few people pay some tribute to
          it.
        </p>


        <p>
          <div class="text-h4">Time &amp; Money</div><br>

          <ul>
            <li>amplifier: &euro; 105 (including shipping)</li>
            <li>parts: &euro; 103 (from <tt>mouser.com</tt>)</li>
            <li>materials (solder etc.): &euro; 15 (estimate)</li>
          </ul>
          <br/>

          Time spent: about 10 hours doing research, and 25 hours for the actual work.

        </p>

      </v-card-text>

    </v-card>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
export default {
  name: 'Restore',

  components: {
    Social: () => import('@/components/home/Social'),
    Subscribe: () => import('@/components/home/Subscribe')
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 32px;
}

</style>
