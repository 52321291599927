<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/articles/denon_brochure_banner.jpg`)" contain height="200px"></v-img>

      <v-card-title>
        Denon PMA Brochure
      </v-card-title>

      <!-- <v-card-subtitle>
        some subtitle
           </v-card-subtitle> -->


      <v-card-text>
        <p>Some nice info on the PMA amplifier series in this brochure (in Dutch),
          as well as the TU 660, 460, and 260 tuners.
        </p>
        <p>It mentions you could get the PMA-560 with wooden side panels (!), never saw these.
        </p>

      </v-card-text>

      <v-card-actions>
        <v-btn color="orange lighten-2" text @click="download_brochure">
          Download
        </v-btn>

        <v-spacer></v-spacer>

      </v-card-actions>
      
    </v-card>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
  export default {
    name: 'Brochure',

    components: {
      Social: () => import('@/components/home/Social'),
      Subscribe: () => import('@/components/home/Subscribe')
    },

    methods: {
      download_brochure() {
        window.open('denon_pma_brochure.pdf', '_blank');
      }
    }
  }
</script>
