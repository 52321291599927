<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/articles/pma560_relays.jpg`)" height="200px"></v-img>

      <v-card-title>
        Denon PMA-560 Speaker Relay Repair
      </v-card-title>

      <!-- <v-card-subtitle>
        some subtitle
           </v-card-subtitle> -->


      <v-card-text>
        <p>This is just a pointer to an excellent article about diagnosing and repairing a speaker relay problem:
          <a href="https://www.devroom.io/projects/repair-denon-pma-560" target="_blank">Repair: Denon PMA-560</a>.
        </p>

        <p>
          My 560 had a similar problem recently, you can
          <a href="https://www.diyaudio.com/forums/solid-state/367105-denon-pma-560-output-relay-tripping.html" target="_blank">
            follow the thread at diyaudio</a>.
        </p>

      </v-card-text>

    </v-card>
    <social />
  </v-container>
</template>

<script>
  export default {
    name: 'ServiceManual',

    components: {
      Social: () => import('@/components/home/Social')
    },

    methods: {
    }
  }
</script>
