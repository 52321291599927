<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/articles/pma560_receipt.jpg`)" height="200px"></v-img>

      <v-card-title>
        Denon PMA-560 Prices
      </v-card-title>

      <!-- <v-card-subtitle>
        some subtitle
           </v-card-subtitle> -->


      <v-card-text>
        <p>Way back in 1990 I was making some extra money as a teaching assistant in the Leiden University
          Computer Science department, and spent a sizable part of it on audio equipment. My first big purchase
          was the amplifier, bought on Wednesday November 7, 1990 at "Hifi Video Centrum Overgaauw" in the Breestraat in Leiden.
          They still exist (!) and
          are <a href="https://overgaauw.com/product-categorie/audio-producten/losse-stereo-componenten/versterkers/?jet-smart-filters=jet-woo-products-grid/gridProducten&_tax_query_pa_merk=688" target="_blank">are doing well</a> <tt>:-)</tt>.
          I remember comparing a few amps, and really liking the "warm sound" of the Denon.
        </p>

        <p>The price at the time was 699 Dutch guilders, which translates to about 585 euros in today's money.
          These days the PMA 560 pops up on ebay and similar sites ("marktplaats" in the Netherlands, catawiki) pretty often.
          They tend to go for around 100 euros, which is a steal, especially if you're able to fix them up yourself.
        </p>

        <p>Because people tend to vary the way they describe the amp ("denon 560", "pma560", "pma-560", etc.), it is tricky to
          come up with a search term that catches them all:<br/>
          <a href="https://www.marktplaats.nl/q/denon+260+%7c+denon+360+%7c+denon+560+%7c+denon+860+%7c+denon+1060+%7c+pma-560+%7c+%22pma+560%22+%7c+pma-260+%7c+%22pma+260%22+%7c+pma-360+%7c+%22pma-360%22+%7c+pma-860+%7c+%22pma+860%22+%7c+pma-1060+%7c+%22pma+1060%22+%7c+pma260+%7c+pma360+%7c+pma560+%7c+pma860+%7c+pma1060" target="_blank">Click here</a> to search
          for all "x60" series Denon amplifiers on the Dutch <em>marktplaats</em> (market place) site (so the PMA 260, 360, 560, 860, and 1060).
        </p>

        <p>For now a <a href="https://www.marktplaats.nl/q/denon+1560+%7c+pma-1560+%7c+%22pma+1560%22+%7c+pma1560" target="_blank">separate search</a> for the PMA 1560, it
          seems I ran into a query size limit otherwise.
        </p>

        <p>The "hifishark" site collects used hifi for sale across the world:
          <ul>
            <li><a href="https://www.hifishark.com/search?q=denon+pma+560" target="_blank">PMA 560</a></li>
            <li><a href="https://www.hifishark.com/search?q=denon+pma+860" target="_blank">PMA 860</a></li>
            <li><a href="https://www.hifishark.com/search?q=denon+pma+1060" target="_blank">PMA 1060</a></li>
            <li><a href="https://www.hifishark.com/search?q=denon+pma+1560" target="_blank">PMA 1560</a></li>
          </ul>
        </p>

        <p>When did you buy your 560? Did you buy it new or used? How much was it? Send me e-mail <tt>:-)</tt>.
        </p>

      </v-card-text>

    </v-card>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
  export default {
    name: 'Prices',

    components: {
      Social: () => import('@/components/home/Social'),
      Subscribe: () => import('@/components/home/Subscribe')
    },

    methods: {
    }
  }
</script>
